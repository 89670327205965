import React from "react"

interface Props {
  title: string
  subtitle: string
  url?: string
  documentUrl?: string
}

const GuideDownload = ({title, subtitle, url, documentUrl}: Props) => {
  return (
    <div className="documents" key={title}>
      {documentUrl ? (
        <a
          className="guide"
          href={documentUrl}
          target="_blank"
          rel="noreferrer"
        >
          <span className="download nopdf">
            <span className="title">{title}</span>
          </span>
        </a>
      ) : url ? (
        <a
          className="guide"
          href={url.replace("https://www.datocms-assets.com", "/documents")}
          target="_blank"
          rel="noreferrer"
        >
          <span className="download">
            <span className="title">{title}</span>
            <span className="comments">{subtitle}</span>
          </span>
        </a>
      ) : (
        <p>Geen actuele handreikingen beschikbaar.</p>
      )}
    </div>
  )
}

export default GuideDownload
