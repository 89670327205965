import {graphql, Link} from "gatsby"
import React from "react"
import GuideDownload from "../components/guides/guide-download"
import Layout from "../components/layout"
import parse from "html-react-parser"
import {SEO} from "../components/seo"

interface GuideQuery {
  datoCmsGuide: {title: string}
  data: {
    datoCmsGuide: {
      title: string
      subtitle: string
      body: string
      archive: boolean
      document: {
        url: string
      }
      documentUrl: string
    }
    allDatoCmsPublication: {
      nodes: GuidePublicationData[] | []
    }
  }
}

interface GuidePublicationData {
  title: string
  number: number
  numberSuffix: string | null
  obsolete: boolean
  pgsLabel: string
}

const Guide = ({data}: GuideQuery) => {
  const {title, subtitle, body, archive, document, documentUrl} =
    data.datoCmsGuide
  const publications: GuidePublicationData[] = data.allDatoCmsPublication.nodes
  return (
    <div className="guides">
      <Layout currentLocation={title}>
        <h1>{title}</h1>
        <p className="introduction">{subtitle}</p>
        {parse(body)}
        <h2>Handreiking</h2>
        <GuideDownload
          title={title}
          subtitle={subtitle}
          url={document?.url}
          documentUrl={documentUrl}
        />
        <div className="publicationlinks">
          {publications.length > 0 && <h2>Gerelateerde publicaties</h2>}
          {publications.map(publication => {
            return (
              <div className="dotted" key={publication.title}>
                <Link
                  to={`/publicaties/${publication.pgsLabel}`}
                  className={`clearfix ${
                    publication.obsolete ? "light_blue" : ""
                  }`}
                >
                  <span
                    className={`number ${
                      publication.numberSuffix ? "suffix" : ""
                    }`}
                  >
                    PGS {publication.number}
                    {publication.numberSuffix && `-${publication.numberSuffix}`}
                  </span>
                  <span className="name">{publication.title}</span>
                </Link>
              </div>
            )
          })}
        </div>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($id: String) {
    datoCmsGuide(id: {eq: $id}) {
      title
      subtitle
      body
      archive
      document {
        url
      }
      documentUrl
    }
    allDatoCmsPublication(filter: {guides: {elemMatch: {id: {eq: $id}}}}) {
      nodes {
        number
        numberSuffix
        title
        obsolete
        pgsLabel
      }
    }
  }
`

export default Guide

export const Head = ({data}: GuideQuery) => {
  return <SEO title={data.datoCmsGuide.title} />
}
